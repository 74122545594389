import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import Sticky from '@components/Sticky'

const Posts = ({
  data: { posts = {}, featuredPosts = {}, recentPosts = {} },
  ...props
}) => {
  const { pageContext: { services = {} } = {} } = props

  return (
    <Layout {...props}>
    <Seo title='Home' />
      <Divider space="1"/>
      <Stack>
        <CardList
              nodes={recentPosts.nodes}
              columns={[1, 2]}
              variant={['horizontal-md', 'horizontal']}
        />
      </Stack>
      <Divider space={2} />
      <Stack>
        <Main>{services.mailchimp && <NewsletterExpanded />}</Main>
      </Stack>
    </Layout>
  )
}

export default Posts
